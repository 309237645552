import React from "react";
import { Auth0Client } from '@auth0/auth0-spa-js';
import { callGetApi } from '../helpers/apiHelper';
import { helperCompany } from "@buyerson/pricingsaas-companies";
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'

const auth0 = new Auth0Client({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  app_api: process.env.REACT_APP_API
});

export class CompaniesControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companiesCount: 0,
      companies: [],
      companyId: ""
    }
  };


  setState(state) {
    super.setState(state);
  }

  removeCompany(index) {
    const companies = this.state.companies;
    const slug = companies[index].companyId;
    helperCompany.removeCompany(companies, companies[index]);
    const callback = (data) => {
      this.setState(state => {
        const companyFilter = state.companies.filter((j) => index !== j);
        return {
          message: data.message,
          companies: companyFilter
        }
      });
      // window.location.href = "/companies";
    }
    callGetApi(process.env.REACT_APP_AUDIENCE + "/scraper/archiveCompany?slug=" + slug, callback);
  }

  componentDidMount() {
    if (!this.state.companiesCount) {
      auth0.getTokenSilently({
        audience: process.env.REACT_APP_AUDIENCE
      })
        .then(accessToken => {

          fetch(process.env.REACT_APP_AUDIENCE + '/scraper/admindata', {
            method: 'GET',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
              Authorization: 'Bearer ' + accessToken
            }
          }).then(response => response.json())
            .then((data) => {
              this.setState({
                companiesCount: data.companies.length,
                companies: data.companies,
              });
            });
        }
        )
        .catch(e => console.log(e));
    }
  }

  render() {
    if (this.state.companiesCount) {
      return (
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Companies</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the editable companies in your account
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                <a href={"/editCompany?new=true"}>Add Company</a>
              </button>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Company
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 ">
                          Number of employees
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {this.state.companies.map((company, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <div className="flex flex-row align-middle">
                            <img className="h-8" src={company.logoUrl}/><a href={"/editPricebook?slug=" + company.companyId}><span className="block ml-3 pt-1">{company.companyId}</span></a>
                          </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{company.employees}</td>
                          <div>
                            <Menu as="div" className="relative inline-block text-left">
                              <div>
                                <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                  <span className="sr-only">Open options</span>
                                  <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1" style={{margin: "5px"}}>
                                    <Menu.Item>
                                      {company.pricingPageScreenshotUrl ?
                                        <a target="_blank" rel="noreferrer" href={company.pricingPageScreenshotUrl}>Screenshot</a>
                                        :
                                        <></>
                                      }
                                    </Menu.Item>
                                    <form>
                                      <Menu.Item>
                                        <a href={"/editCompany?slug=" + company.companyId}>Edit Company</a>
                                      </Menu.Item>
                                    </form>
                                    <Menu.Item>
                                      <a href={"/editPricebook?slug=" + company.companyId}>Edit Pricebook</a>
                                    </Menu.Item>
                                    <form method="POST" action="#">
                                      <Menu.Item>
                                        <a href={"/viewPricebook?slug=" + company.companyId}>View Pricebook</a>
                                      </Menu.Item>
                                    </form>
                                    <Menu.Item>
                                      <button
                                        onClick={() => this.removeCompany(index)}
                                        type="button"
                                      >
                                        Inactivate
                                      </button>
                                    </Menu.Item>

                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          Loading Company...
        </div>
      );
    }
  }
}


export default CompaniesControl;