import React from "react";
import { pricebookValidation } from '@buyerson/pricingsaas-pricebook';
import { callGetApi } from "../helpers/apiHelper";

export class HomeControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = JSON.parse(window.localStorage.getItem('state')) || {
      refreshing: false,
      data: [],
      freemium: 0,
      primary_value_metric: 0,
      plan_names: 0,
      plan_prices: 0
    }
  }

  setState(state) {
    window.localStorage.setItem('state', JSON.stringify(state));
    super.setState(state);
  }

  componentDidMount() {
    this.refresh();
  }

  countFreemiumValid(pricebooks) {
    let count = 0;
    for (let i = 0; i < pricebooks.length; i++) {
      const pricebook = pricebooks[i].pricebook;
      const check = pricebookValidation.doesValidationExist(pricebook, "freemium")
      if (check) {
        count = count + 1;
      }
    }
    return count;
  }

  getPrimaryMetricValue(pricebooks) {
    let count = 0;
    for (let i = 0; i < pricebooks.length; i++) {
      const pricebook = pricebooks[i].pricebook;
      const check = pricebookValidation.doesValidationExist(pricebook, "primary_value_metric");
      if (check) {
        count = count + 1;
      }
    }
    return count;
  }

  getValidPlanName(pricebooks) {
    let count = 0;
    for (let i = 0; i < pricebooks.length; i++) {
      const pricebook = pricebooks[i].pricebook;
      const check = pricebookValidation.doesValidationExist(pricebook, "plan_names");
      if (check) {
        count = count + 1;
      }
    }
    return count;
  }

  getValidPlanPrice(pricebooks) {
    let count = 0;
    for (let i = 0; i < pricebooks.length; i++) {
      const pricebook = pricebooks[i].pricebook;
      const check = pricebookValidation.doesValidationExist(pricebook, "plan_prices");
      if (check) {
        count = count + 1;
      }
    }
    return count;
  }

  refresh() {
    const callback = (data) => {
      this.setState({
        refreshing: false,
        data: data.data,
        freemium: this.countFreemiumValid(data.data),
        primary_value_metric: this.getPrimaryMetricValue(data.data),
        plan_names: this.getValidPlanName(data.data),
        plan_prices: this.getValidPlanPrice(data.data),
      });
    }
    this.setState({
      refreshing: true
    })
    console.log('calling', process.env.REACT_APP_AUDIENCE + "/scraper/getCompaniesAndPricebooks");
    callGetApi(process.env.REACT_APP_AUDIENCE + "/scraper/getCompaniesAndPricebooks", callback);
  }

  render() {

    return (
      <>
        Welcome to admin console
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">Verification</h3>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-6" >
            <div className="overflow-hidden rounded-lg bg-white px-1 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">Free Plans: </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{this.state.freemium}</dd>
            </div>

            <div className="overflow-hidden rounded-lg bg-white px-1 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">Plan names: </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{this.state.plan_names}</dd>
            </div>

            <div className="overflow-hidden rounded-lg bg-white px-1 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">Plan prices: </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{this.state.plan_prices}</dd>
            </div>

            <div className="overflow-hidden rounded-lg bg-white px-1 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">Primary value metrics: </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{this.state.primary_value_metric}</dd>
            </div>
          </dl>
        </div>
        <div>
          <button onClick={() => this.refresh()}>
            {this.state.refreshing ? "Refreshing" : "Refresh"}
          </button>
        </div>
      </>

    )

  }
}


export default HomeControl;