import React from "react";
import { helperCompany } from '@buyerson/pricingsaas-companies/dist';
import { callPutApi } from '../helpers/apiHelper';
import { callGetApi } from "../helpers/apiHelper";

export class EditCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: "",
      company: {},
      pricingPageUrl: "",
      value: '',
      companyId: "",
      addCompanyState: false,
      archived: false,
      newCompany: ""
    };
  }

  componentDidMount() {

    const queryParams = new URLSearchParams(window.location.search);
    const slug = queryParams.get("slug");
    const newCompany = queryParams.get("new");
    this.setState({ slug })
    const callback = (data) => {
      this.setState({
        company: data.company,
        pricingPageUrl: data.company.pricingPageUrl,
        pricingModel: data.company.pricingModel,
        pricingMetric: data.company.pricingMetric,
        acquisitionModel: data.company.acquisitionModel,
        employeeNum: data.company.employees,
        logoUrl: data.company.logoUrl,
        companyId: data.company.companyId,
        archived: data.company.archived
      });
    }
    if (!newCompany) {
      callGetApi(process.env.REACT_APP_AUDIENCE + "/scraper/getCompany?slug=" + slug, callback);
    }
    else {
      this.setState({
        company: helperCompany.createCompany(""),
        pricingPageUrl: "",
        pricingModel: "",
        pricingMetric: "",
        acquisitionModel: "",
        employeeNum: 0,
        logoUrl: "",
        companyId: "",
        newCompany: true
      });
    }
  }

  submitForm() {
    const company = this.state.company;
    company.pricingPageUrl = this.state.pricingPageUrl;
    company.pricingModel = this.state.pricingModel;
    company.pricingMetric = this.state.pricingMetric;
    company.acquisitionModel = this.state.acquisitionModel;
    company.employees = this.state.employeeNum;
    company.logoUrl = this.state.logoUrl;

    if (this.state.archived) {
      this.removeCompany(company);
    }
    else if (!this.state.archived) {
      this.addCompany(company);
    }

    const callback = (data) => {
      this.setState({
        message: data.message
      });
      window.location.href = "/";
    }
    callPutApi(process.env.REACT_APP_AUDIENCE + "/scraper/updateCompany", company, callback);
  }

  submitAddCompany() {
    const slug = this.state.companyId;
    const newCompany = helperCompany.createCompany(slug);
    newCompany.pricingPageUrl = this.state.pricingPageUrl;
    newCompany.pricingModel = this.state.pricingModel;
    newCompany.pricingMetric = this.state.pricingMetric;

    newCompany.acquisitionModel = this.state.acquisitionModel;

    newCompany.employees = this.state.employeeNum;
    newCompany.logoUrl = this.state.logoUrl;
    const callBack = (data) => {
      this.setState({
        message:data.message
      });
      window.location.href = "/companies";
    }
    callPutApi(process.env.REACT_APP_AUDIENCE + "/scraper/addCompany", newCompany, callBack);
  }

  removeCompany(company) {
    company.archived = true;
    return company;
  }

  addCompany(company) {
    company.archived = false;
    return company;
  }

  getEditContent() {
    return (
      <>
        <form className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">Company Profile</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  This section is for editing company information
                </p>
              </div>

              <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Archived:
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="flex max-w-lg rounded-md shadow-sm">
                    <label>
                      <input type='checkbox' checked={this.state.archived} onChange={() => { this.setState({ archived: !this.state.archived }) }} />
                      &nbsp; Is Archived
                    </label>
                  </div>
                </div>
              </div>
            </div>

              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Pricing Page Url
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        value={this.state.pricingPageUrl}
                        id="username"
                        autoComplete="username"
                        onChange={(event) => this.setState({ pricingPageUrl: event.target.value })}
                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Pricing Model
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        value={this.state.pricingModel}
                        id="username"
                        autoComplete="username"
                        onChange={(event) => this.setState({ pricingModel: event.target.value })}
                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Pricing Metric
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        value={this.state.pricingMetric}
                        id="username"
                        autoComplete="username"
                        onChange={(event) => this.setState({ pricingMetric: event.target.value })}
                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Acquisition Model
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        value={this.state.acquisitionModel}
                        id="username"
                        autoComplete="username"
                        onChange={(event) => this.setState({ acquisitionModel: event.target.value })}
                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Employee Count

                  </label>

                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        value={this.state.employeeNum}
                        id="username"
                        autoComplete="username"
                        onChange={(event) => this.setState({ employeeNum: event.target.value })}
                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Logo Url
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        id="username"
                        autoComplete="username"
                        value={this.state.logoUrl}
                        onChange={(event) => this.setState({ logoUrl: event.target.value })}
                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Helper
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg rounded-md shadow-sm">

                      <a href={"https://www.linkedin.com/company/" +  this.state.companyId} target="_blank">{"https://www.linkedin.com/company/" +  this.state.companyId}</a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </form>
        <div className="pt-5">
            <div className="flex justify-end">
              <button
                onClick={() => this.submitForm()}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Save
              </button>
            </div>
          </div>
      </>
    )
  }

  getAddCompany() {
    return (
      <>
        <form className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">New Company Profile</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  This section is for editing company information
                </p>
              </div>

              <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Create Company Name
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        value={this.state.companyId}
                        id="username"
                        autoComplete="username"
                        onChange={(event) => this.setState({ companyId: event.target.value })}
                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Pricing Page Url
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        value={this.state.pricingPageUrl}
                        id="username"
                        autoComplete="username"
                        onChange={(event) => this.setState({ pricingPageUrl: event.target.value })}
                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Employee Count
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex flex-col max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        value={this.state.employeeNum}
                        id="username"
                        autoComplete="username"
                        onChange={(event) => this.setState({ employeeNum: event.target.value })}
                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />

                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Logo Url
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        id="username"
                        autoComplete="username"
                        value={this.state.logoUrl}
                        onChange={(event) => this.setState({ logoUrl: event.target.value })}
                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="pt-5">
            <div className="flex justify-end">
              <button
                onClick={() => this.submitAddCompany()}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Add new company
              </button>
            </div>
          </div>
      </>
    )
  }

  render() {
    const slug = this.state.slug;
    const company = this.state.company;
    let content = "";
    if (!company) {
      content = <>Loading company...</>
    }
    else if (this.state.newCompany) {
      content = this.getAddCompany();
    }
    else {
      content = this.getEditContent();
    }

    return (<>
      <div className="flex flex-col pt-9">
        <div>Edit company: {slug}</div>
        <div>{content}</div>
      </div></>)

  }
}
export default EditCompany;