import React from 'react';
import {pricebookValidation} from '@buyerson/pricingsaas-pricebook';
import { callGetApi } from "../helpers/apiHelper";
// import ReactJson from 'react-json-view';

export class ViewPricebook extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slug: "",
            pricebook: {},
            freemium: false,
            planName: "",
            price: "",
            planType: "",
            plans: [],
            valueMetricName: ""
        }
    }

    toDateTime(secs) {
        var t = new Date(secs);
        return t.toLocaleDateString('en-us', { month:"short", day:"numeric"}) + ", " +  t.toLocaleTimeString('en-US');
    }

    componentDidMount() {

        const queryParams = new URLSearchParams(window.location.search)
        const slug = queryParams.get("slug");
        this.setState({ slug })
        const callback = (data) => {
          const planName = [];
          for (let i = 0; i < data.data.plans.length; i++) {
            planName.push(data.data.plans[i].name);
          }
          this.setState({
            pricebook: data.data,
            freemium: pricebookValidation.getValidationTimestamp(data.data, "freemium") ? this.toDateTime(pricebookValidation.getValidationTimestamp(data.data, "freemium")) : "--",
            primary_value_metric: pricebookValidation.getValidationTimestamp(data.data, "primary_value_metric") ? this.toDateTime(pricebookValidation.getValidationTimestamp(data.data, "primary_value_metric")) : "--",
            planName: pricebookValidation.getValidationTimestamp(data.data, "plan_names") ? this.toDateTime(pricebookValidation.getValidationTimestamp(data.data, "plan_names")) : "--",
            plan_prices: pricebookValidation.getValidationTimestamp(data.data, "plan_prices") ? this.toDateTime(pricebookValidation.getValidationTimestamp(data.data, "plan_prices")) : "--"
          });
        }
        callGetApi(process.env.REACT_APP_AUDIENCE + "/scraper/getPricebook?slug=" + slug, callback);
      }

      getContent() {
        return (
            <>
              <form className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                  <div className="space-y-6 sm:space-y-5">
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-gray-900">Validation Status</h3>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">
                        This section is for viewing pricebook validation status
                      </p>
                    </div>

                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Freemium Validation
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            {this.state.freemium}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Primary Value Metric Validation
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            {this.state.primary_value_metric}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Plan Name Validation
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            {this.state.planName}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Plan Price Validation
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <div className="flex max-w-lg rounded-md shadow-sm">
                            {this.state.plan_prices}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="space-y-6 sm:space-y-5">
                    <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Pricebook JSON:
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      {/* <ReactJson src={this.state.pricebook} /> */}
                    </div>
                    </div>
                    {/* <div>
                      Pricebook JSON:
                      <ReactJson src={this.state.pricebook} />
                    </div> */}
                  </div>
                </div>
              </form>
            </>
          )
      }

      render() {
        const slug = this.state.slug;
        let content = "";
        if (!this.state.plans) return <>loading...</>
        content = this.getContent();
        return (<>
          <div className="flex flex-col pt-9">
            <div>View Pricebook: {slug}</div>
            <div>{content}</div>
          </div></>)

      }
}

export default ViewPricebook;