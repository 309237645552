import React, { Fragment } from "react";
import { callGetApi, callPutApi } from '../helpers/apiHelper';
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'

export const COLUMNS = {
  COMPANYID: 'companyid',
  TASKTYPE: 'tasktype',
  STATE: 'state',
  LASTMODIFIED: 'lastmodifed',
}

export class TasksControl extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

    this.setState({
      tasksNew: [],
      tasksInVerification: [],
      completedTasks: [],
      tasksData: [],
      loading: true,
      sortAscending: true,
      sortBy: 'taskType',
    });

    this.refreshData();
  }

  updateState(taskId, state) {

    const callback = (data, isOk) => {
      this.setState({
        message: data.message
      });

      if (isOk) {
        this.refreshData();
      } else {
        alert('Error: ' + data.message);
      }
    }

    callGetApi(process.env.REACT_APP_AUDIENCE + "/scraper/updateTask?state=" + state + "&taskId=" + taskId, callback);
  }

  refreshData() {
    const callback = (data) => {
      // const taskData = data.tasksNew.concat(data.tasksInVerification, data.latestTasks);
      const taskData = data.tasksInVerification; //data.tasksNew.concat(data.tasksInVerification, data.latestTasks);
      this.sortByColumn(COLUMNS.TASKTYPE, taskData);
      this.setState({
        tasksNew: data.tasksNew ? data.tasksNew : [],
        completedTasks: [], //data.latestTasks ? data.latestTasks : [],
        loading: false,
        tasksData: taskData,
        newTaskSlug: ""
      });
    }

    const callback2 = (data) => {
      let results = data.results ? data.results : [];
      results.sort((plan1, plan2) => {
        return (plan1.badScreenshot === plan2.badScreenshot) ? 0 : plan1.badScreenshot ? -1 : 1;
      });

      this.setState({
        tasksInVerification : results
      });
    }

    callGetApi(process.env.REACT_APP_AUDIENCE + '/scraper/admindata', callback);
    callGetApi(process.env.REACT_APP_AUDIENCE + '/scraper/getVerificationTasks', callback2);
  }

  getSortFn(sortByArg, tasksData = this.state.tasksData) {
    const sortBy = sortByArg ? sortByArg : this.state.sortBy;
    const sortAscending = this.state.sortAscending;
    if (sortBy) {
      if (sortAscending) {
        if (sortByArg === COLUMNS.COMPANYID) {
          const idAscending = [...tasksData].sort((a, b) =>
            a.companyid > b.companyid ? 1 : -1
          );
          this.setState({
            tasksData: idAscending
          })
        }
        else if (sortByArg === COLUMNS.TASKTYPE) {
          const taskAscending = [...tasksData].sort((a, b) => {
            if (a.taskType === b.taskType) {
              return a.lastModified - b.lastModified ? 1 : -1
            }
            return a.taskType > b.taskType ? 1 : -1
          }
          );
          this.setState({
            tasksData: taskAscending
          });
        }
        else if (sortByArg === COLUMNS.STATE) {
          const stateAscending = [...tasksData].sort((a, b) =>
            a.state > b.state ? 1 : -1
          );
          this.setState({
            tasksData: stateAscending
          });
        }
        else if (sortByArg === COLUMNS.LASTMODIFIED) {
          const lastModifiedAscending = [...tasksData].sort((a, b) =>
            a.lastModified - b.lastModified
          );
          this.setState({
            tasksData: lastModifiedAscending
          });
        }
      }
      else if (!sortAscending) {
        if (sortByArg === COLUMNS.COMPANYID) {
          const idDescending = [...tasksData].sort((a, b) =>
            a.companyid < b.companyid ? 1 : -1
          );
          this.setState({
            tasksData: idDescending
          });
        }
        else if (sortByArg === COLUMNS.TASKTYPE) {
          const taskDescending = [...tasksData].sort((a, b) => {
            if (a.taskType === b.taskType) {
              return b.lastModified - a.lastModified ? 1 : -1
            }
            return b.taskType > a.taskType ? 1 : -1
          }
          );
          this.setState({
            tasksData: taskDescending
          });
        }
        else if (sortByArg === COLUMNS.STATE) {
          const stateDescending = [...tasksData].sort((a, b) =>
            b.state > a.state ? 1 : -1
          );
          this.setState({
            tasksData: stateDescending
          });
        }
        else if (sortByArg === COLUMNS.LASTMODIFIED) {
          const lastModifiedDescending = [...tasksData].sort((a, b) =>
            b.lastModified - a.lastModified
          );
          this.setState({
            tasksData: lastModifiedDescending
          })
        }
      }
    }
  }

  sortByColumn(column, tasksData = this.state.tasksData) {
    this.setState({sortBy: column, sortAscending: !this.state.sortAscending,});
    this.getSortFn(column, tasksData);
  }

  verifyTask(taskId) {
    const callback = (data, isOk) => {
      this.setState({
        message: data.message
      });

      if (isOk) {
        this.refreshData();
      } else {
        alert('Error: ' + data.message);
      }
    }

    callGetApi(process.env.REACT_APP_AUDIENCE + '/scraper/verifyTask?taskId=' + taskId, callback);
  }

  archiveTask(taskId) {

    if (!taskId) {
      alert('Task id cannot be empty');
      return;
    }

    const callback = (data, isOk) => {

      if (isOk) {
        this.setState({ newTaskSlug: "" });
        this.refreshData();
      } else {
        alert('Failed task archive: ' + data.message);
      }
    }

    callGetApi(process.env.REACT_APP_AUDIENCE + "/scraper/updateTask?state=archived&taskId=" + taskId, callback);

  }
  createTask(taskType) {

    if (!this.state.newTaskSlug) {
      alert('Task slug cannot be empty');
      return;
    }

    const callback = (data, isOk) => {

      if (isOk) {
        this.setState({ newTaskSlug: "" });
        this.refreshData();
        alert('Success!');
      } else {
        alert('Failed! ' + data.message);
      }
    }

    callPutApi(process.env.REACT_APP_AUDIENCE + '/scraper/newTask?slug=' + this.state.newTaskSlug + '&taskType=' + taskType, {}, callback);
  }

  toDateTime(secs) {
    var t = new Date(secs);
    return t.toLocaleDateString('en-us', { month: "short", day: "numeric" }) + ", " + t.toLocaleTimeString('en-US');
  }

  renderMenu(company) {
    if (company.state === "new") {
      return (
        <>
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1" style={{ margin: "5px" }}>
              <form>
                <Menu.Item>
                  <a href={"/editTask?taskId=" + company.taskId}>View</a>
                </Menu.Item>
              </form>
              <Menu.Item>
                <a target="_blank" href={process.env.REACT_APP_AUDIENCE + "/scraper/showTask?taskId=" + company.taskId}>Preview</a>
              </Menu.Item>
              <form method="POST" action="#">
                <Menu.Item>
                  <a onClick={() => this.archiveTask(company.taskId)} >Archive</a>
                </Menu.Item>
              </form>
            </div>
          </Menu.Items>
        </>
      );
    }
    else if (company.state === "inverification") {
      return (
        <>
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1" style={{ margin: "5px" }}>
              <form>
                <Menu.Item>
                  <a href={"/editTask?taskId=" + company.taskId}>View</a>
                </Menu.Item>
              </form>
              <Menu.Item>
                <a onClick={() => this.updateState(company.taskId, 'new')} >Back to New</a>
              </Menu.Item>
              <form method="POST" action="#">
                <Menu.Item>
                  <a onClick={() => this.verifyTask(company.taskId)} > Apply Change</a>
                </Menu.Item>
              </form>
              <form method="POST" action="#">
                <Menu.Item>
                  <a onClick={() => this.archiveTask(company.taskId)} > Archive</a>
                </Menu.Item>
              </form>
            </div>
          </Menu.Items>
        </>
      );
    }
    else if (company.state === "done") {
      return (
        <>
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1" style={{ margin: "5px" }}>
              <form>
                <Menu.Item>
                <a href={"/editTask?taskId="+company.taskId}>View</a>
                </Menu.Item>
              </form>
            </div>
          </Menu.Items>
        </>
      );
    }
  }

  render() {

    if (!this.state || this.state.loading) {
      return (<>Loading ...</>);
    }

    return (
      <>
        <div className="p-9">
          <h1 className="font-bold">New Task</h1>
          <input className="border border-gray-600" type="text" value={this.state.newTaskSlug} placeholder="company slug" name="newTaskText" onChange={(event) => { this.setState({ newTaskSlug: event.target.value }) }} />
          <button className="ml-3 border border-indigo-600 pl-3 pr-3" type="button" onClick={() => this.createTask("freemiumCheck")}>Create Freemium Task</button>
          <button className="ml-3 border border-indigo-600 pl-3 pr-3" type="button" onClick={() => this.createTask("valueMetric")}>Create VM Task</button>
          <button className="ml-3 border border-indigo-600 pl-3 pr-3" type="button" onClick={() => this.createTask("planNames")}>Create Plans Task</button>
          <button className="ml-3 border border-indigo-600 pl-3 pr-3" type="button" onClick={() => this.createTask("captureScreenshot")}>Capture Screenshot</button>
          <button className="ml-3 border border-indigo-600 pl-3 pr-3" type="button" onClick={() => this.createTask("trasnscribe")}>Create Transcript</button>
        </div>

        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6" onClick={() => this.sortByColumn(COLUMNS.COMPANYID)}>
                      CompanyID
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6" onClick={() => this.sortByColumn(COLUMNS.TASKTYPE)}>
                      Task Type
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6" onClick={() => this.sortByColumn(COLUMNS.STATE)}>
                      State
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6" onClick={() => this.sortByColumn(COLUMNS.LASTMODIFIED)}>
                      Last Modified
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {this.state.tasksData.map((task, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <div className="flex flex-row align-middle"><a href={"/editCompany?slug=" + task.companyid}>{task.companyid}</a></div>
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {task.taskType}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {task.state}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {this.toDateTime(task.lastModified)}
                      </td>
                      <Menu as="div" className="relative inline-block text-left">
                        <div>
                          <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 mt-3">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {this.renderMenu(task)}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );


  }
}


export default TasksControl;