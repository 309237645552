import React from "react";
import { callGetApi } from '../helpers/apiHelper';

export class EditTaskControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      task: {},
      futureState: "new",
      message : ""
    };
  }
  componentDidMount() {

    const queryParams = new URLSearchParams(window.location.search)
    const taskId = queryParams.get("taskId");
    this.setState({taskId})

    const callbackVerify = (data) => {
      this.setState({
        message : data.message
      });
      console.log(data);
    }

    const callback = (data) => {

      console.log(data);
      console.log('got callback from getTask')

      this.setState({
        task : data.task,
        futureState : data.task.state
      });
      const taskState = data.task && data.task.state ? data.task.state : "";

      // if (taskState === 'inverification') {
      //   callGetApi(process.env.REACT_APP_AUDIENCE + '/scraper/verifyTask?taskId='+taskId, callbackVerify);
      // } else {
        this.setState({
          message : "The task is currently in " + taskState + " state."
        });
      // }
    }

    callGetApi(process.env.REACT_APP_AUDIENCE + '/scraper/getTask?taskId='+taskId, callback);
  }

  updateState() {

    const state = this.state.futureState;

    const callback = (data, isOk) => {
      this.setState({
        message : data.message
      });

      if (isOk) {
        console.log(data);
        alert('success');
        window.location.href = "/tasks";
      } else {
        alert('Error: ' + data.message);
      }
    }

    const taskId = this.state.task.taskId;
    // callGetApi("https://tasks.pricingsaas.com/scraper/updateTask?state=" + state + "&taskId="+taskId, callback);
    callGetApi(process.env.REACT_APP_AUDIENCE + "/scraper/updateTask?state=" +state+ "&taskId="+taskId, callback);
  }

  verifyTask() {
      const callback = (data, isOk) => {
        this.setState({
          message : data.message
        });

        if (isOk) {
          console.log(data);
          if (data.nextTask) {

            window.location.href = "/editTask?taskId=" + data.nextTask.taskId;
          } else {
            window.location.href = "/tasks";
          }

        } else {
          alert('Error: ' + data.message);
        }
    }

    const taskId = this.state.taskId;

    // callGetApi('https://4p6evsahk1.execute-api.us-east-1.amazonaws.com/dev/submitVerify?taskId='+taskId, callback);
    callGetApi(process.env.REACT_APP_AUDIENCE+ '/scraper/verifyTask?taskId='+taskId, callback);
    // callGetApi(process.env.REACT_APP_AUDIENCE+ '/scraper/verifyTask?taskId='+taskId, callback);
  }

  render() {

    const actionControl = this.state.task && this.state.task.state === 'inverification' ?
          <><button className="ml-3 border border-indigo-600 pl-3 pr-3" type="button" onClick={()=>this.verifyTask()}>Verify</button></>
          :
          <>
            <select value={this.state.futureState} name="state" id="state" onChange={ (event) => { this.setState({futureState : event.target.value}) }}>
              <option value="new">NEW</option>
              <option value="archived">ARCHIVED</option>
              <option value="done">DONE</option>
              <option value="inverification">IN VERIFICATION</option>
            </select>
            <button className="ml-3 border border-indigo-600 pl-3 pr-3" type="button" onClick={()=>this.updateState()}>Update State</button>

          </>

    if (!this.state.task) {
      return (<>Loading</>)
    } else {
      return (<>
              <div className="p-9 flex flex-col">
                <div><span className="font-bold">Company:</span> {this.state.task.companyid}</div>
                <div><span className="font-bold">Value:</span> {this.state.task.result}</div>
                <div><span className="font-bold">Type:</span> {this.state.task.taskType}</div>
                <div><span className="font-bold">Message:</span> {this.state.message}</div>
                <div className="mt-9">{actionControl}</div>
              </div>
              </>)
    }
  }
}


export default EditTaskControl;