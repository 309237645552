import React from "react";
import CompaniesControl from './controls/companiesControl'
import TasksControl from './controls/tasksControl'
import HomeControl from './controls/homeControl'
import EditCompany from './controls/editCompany'
import EditTaskControl from './controls/editTaskControl'
import EditPricebook from './controls/editPricebook'
import { useAuth0 } from "@auth0/auth0-react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";


// import logo from './logo.svg';
import './App.css';
import ViewPricebook from "./controls/viewPricebook";
import ValidateTaskControl from "./controls/validateTaskControl";


// https://github.com/auth0/auth0-react/
// https://auth0.com/docs/quickstart/spa/react/02-calling-an-api#create-an-api


const myRouter =
      <Router>
        <div>
          <nav>
            <Link to="/">Home </Link> |
            <Link to="/companies"> Companies</Link> |
            <Link to="/tasks"> Tasks</Link>
          </nav>
        <Routes>
          <Route path='/' element={<HomeControl/>} />
          <Route path='/companies' element={<CompaniesControl/>} />
          <Route path='/tasks' element={<TasksControl/>} />
          <Route path='/editCompany' element={<EditCompany/>} />
          <Route path='/editTask' element={<EditTaskControl/>} />
          <Route path='/editPricebook' element={<EditPricebook/>} />
          <Route path='/viewPricebook' element={<ViewPricebook/>} />
          <Route path='/validate' element={<ValidateTaskControl/>} />
        </Routes>
        </div>
      </Router>


function App() {
  const { loginWithRedirect, isAuthenticated, logout, user } = useAuth0();

  if (isAuthenticated) {
    // const token = await getTokenSilently();
    return <div className="p-12 flex flex-col">
              <div className="mb-2"  >
                <div className="flex flex-row float-right">
                  <h2 className="mr-4">{user.name}</h2>
                  {/* <p className="mr-4">{user.email}</p> */}
                  <div>
                  <button
                    className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => logout({ returnTo: window.location.origin })}>
                    Log Out
                  </button>
                  </div>
                </div>
              </div>
              <hr/>
              <div >
                  {myRouter}
              </div>
           </div>
  }

  return (
    <div className="App">
        <div className="mt-24 font-bold">
          <h1>Welcome to PricingSaaS Admin Console</h1>
        </div>
        <div className="mt-12">
          <button
            className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => loginWithRedirect()}>Log In</button>
        </div>

    </div>
  );
}

export default App;

