import React from "react";
import { callGetApi } from "../helpers/apiHelper";
import { helperLib } from '@buyerson/pricingsaas-pricebook';

export const TASK_TYPE = {
    CHECK_FOR_FREETRIAL: 'freeTrialCheck',
    CHECK_FOR_FREEMIUM: 'freemiumCheck',
    PLAN_NAMES: 'planNames',
    VALUE_METRIC: 'valueMetric',
    NEW_COMPANY: 'newCompany',
    TRANSCRIBE_SCREENSHOT: 'transcribe',
    CAPTURE_SCREENSHOT: 'captureScreenshot',
    DOWNLOAD_LOGO: 'downloadLogo'
}

export class ValidateTaskControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            task: [],
            company: [],
            pricebook: [],
            data: [],
        }
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        const callback = (data) => {
            console.log(data.data.task );
            this.setState({
                data: data.data,
                task: data.data.task ? data.data.task : [],
                company: data.data.company ? data.data.company : [],
                pricebook: data.data.pricebook ? data.data.pricebook : [],
            });
        }
        callGetApi(process.env.REACT_APP_AUDIENCE + "/scraper/getValidationTask ", callback);
    }

    verifyTask(taskId) {
        const callback = (data, isOk) => {
            this.setState({
                message: data.message
            });

            if (isOk) {
                this.refresh();
            } else {
                alert('Error: ' + data.message);
            }
        }

        callGetApi(process.env.REACT_APP_AUDIENCE + '/scraper/verifyTask?taskId=' + taskId, callback);
    }

    doneTask(taskId) {
        if (!taskId) {
            alert('parameter cannot be empty');
            return;
        }
        const callback = (data, isOk) => {
            if (isOk) {
                this.setState({ newTaskSlug: "" });
                this.refresh();
            } else {
                alert('Failed task archive: ' + data.message);
            }
        }
        callGetApi(process.env.REACT_APP_AUDIENCE + "/scraper/updateTask?state=done&taskId=" + taskId, callback);
    }

    archiveTask(taskId) {

        if (!taskId) {
            alert('Task id cannot be empty');
            return;
        }

        const callback = (data, isOk) => {

            if (isOk) {
                this.setState({ newTaskSlug: "" });
                this.refresh();
            } else {
                alert('Failed task archive: ' + data.message);
            }
        }

        callGetApi(process.env.REACT_APP_AUDIENCE + "/scraper/updateTask?state=archived&taskId=" + taskId, callback);
    }

    checkForFreeTrial(pricebook) {

        return  helperLib.doesPricebookHaveFreeTrial(pricebook) ? 'Freemium' : 'No freemium';
    }

    render() {
        console.log(this.state.task);

        return (
            <>
                {this.state.task.taskType === TASK_TYPE.CAPTURE_SCREENSHOT &&
                    <div>
                        <br />
                        <h1>Validate Tasks</h1>
                        <div className="flex flex-row mt-2">
                            <div className="mr-5">
                                <button onClick={() => this.verifyTask(this.state.task.taskId)} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Verify</button>
                            </div>
                            <div>
                                <button onClick={() => this.archiveTask(this.state.task.taskId)}
                                    className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded ">
                                    Archive
                                </button>
                            </div>
                        </div>
                        <br />
                        <div className="flex flex-row ">
                            <div className="flex flex-column mr-5">
                                <div className="pr-3">
                                    Before:
                                </div>
                                <div>
                                    <img src={this.state.company.pricingPageScreenshotUrl} />
                                </div>
                            </div>
                            <div className="flex flex-column mr-5">
                                <div className="pr-3">
                                    After:
                                </div>
                                <div>
                                    <img src={this.state.task.result.screenshotUrl} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.task.taskType === TASK_TYPE.NEW_COMPANY &&
                    <div>
                        <br />
                        <h1>New Company</h1>
                        <div>
                            <div className="flex flex-row">
                                <div className="pr-3">
                                    Pricing Page Url:
                                </div>
                                <div>
                                    <a href={this.state.task.pricingPageUrl}>{this.state.task.pricingPageUrl}</a>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <button className="mt-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                        <a href={"/editCompany?new=true"} target="_blank">Create Company</a></button>
                                </div>
                            </div>
                            <div className="flex flex-row mt-2">
                                <div className="mr-5">
                                    <button onClick={() => this.doneTask(this.state.task.taskId)}
                                        className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                                        Done</button>
                                </div>
                                <div>
                                    <button onClick={() => this.archiveTask(this.state.task.taskId)}
                                        className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                                        Archive</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {this.state.task.taskType === TASK_TYPE.VALUE_METRIC &&
                    <div>
                        <br />
                        <h1>Value Metrics for {this.state.company.companyId}</h1>
                        <div className="flex flex-row mt-2">
                            <div className="mr-5">
                                <button onClick={() => this.verifyTask(this.state.task.taskId)}
                                    className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                                    Verify</button>
                            </div>
                            <div>
                                <button onClick={() => this.archiveTask(this.state.task.taskId)}
                                    className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded ">
                                    Archive</button>
                            </div>
                        </div>
                        <br />
                        <div className="flex flex-row">
                            <div className="flex flex-column mr-5">
                                <div className="pr-3">
                                    Before:
                                </div>
                                <div>
                                    {this.state.pricebook.valueMetrics.map((valueMetric) =>
                                        <li key={valueMetric.name}>{valueMetric.name}</li>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-column mr-5">
                                <div className="pr-3">
                                    After:
                                </div>
                                <div>
                                    {this.state.task.result}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.task.taskType === TASK_TYPE.PLAN_NAMES &&
                    <div>
                        <br />
                        <h1>Plan names validation for {this.state.company.companyId}</h1>
                        <div className="flex flex-row mt-2">
                            <div className="mr-5">
                                <button onClick={() => this.verifyTask(this.state.task.taskId)} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Verify</button>
                            </div>
                            <div>
                                <button onClick={() => this.archiveTask(this.state.task.taskId)}
                                    className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded ">
                                    Archive</button>
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="flex flex-column mr-5">
                                <div className="pr-3">
                                    Before:
                                </div>
                                <div>
                                    {this.state.pricebook.plans.length === 0 ? <h1>No Plans</h1> :
                                        this.state.pricebook.plans.map((plan) =>
                                            <li key={plan.name}>{plan.name}</li>
                                        )}
                                </div>
                            </div>
                            <div className="flex flex-column mr-5">
                                <div className="pr-3">
                                    After:
                                </div>
                                <div>
                                    {this.state.task.result.map((name) =>
                                        <li key={name}>{name}</li>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.task.taskType === TASK_TYPE.CHECK_FOR_FREEMIUM &&
                    <div>
                        <br />
                        <h1>Check for free trial {this.state.company.companyId}</h1>
                        <div className="flex flex-row mt-2">
                            <div className="mr-5">
                                <button onClick={() => this.verifyTask(this.state.task.taskId)} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Verify</button>
                            </div>
                            <div>
                                <button onClick={() => this.archiveTask(this.state.task.taskId)}
                                    className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded ">
                                    Archive</button>
                            </div>
                        </div>
                        <div className="flex flex-row mt-9">
                            <div className="flex flex-column mr-5">
                                <div className="pr-3">
                                    Before:
                                </div>
                                <div>
                                   <h2> {this.checkForFreeTrial(this.state.pricebook)}</h2>
                                </div>
                            </div>
                            <div className="flex flex-column mr-5">
                                <div className="pr-3">
                                    After:
                                </div>
                                <div>
                                    {this.state.task.result}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default (ValidateTaskControl);