import { Auth0Client } from '@auth0/auth0-spa-js';

const auth0 = new Auth0Client({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID
});

export function callPutApi(apiUrl, payload, callback) {

    return callApi(apiUrl, "PUT", payload, callback);
}

export function callGetApi(apiUrl, callback) {

    return callApi(apiUrl, "GET", null, callback);
}

function callApi(apiUrl, method, payload, callback) {
    auth0.getTokenSilently({
        audience: process.env.REACT_APP_AUDIENCE
      })
      .then(accessToken =>
      {
        const params = method === 'GET' ?
          {
            method: method,
            mode: 'cors', // no-cors, *cors, same-origin
            headers: { Authorization: 'Bearer ' + accessToken  }
          }:
          {
            method: method,
            mode: 'cors', // no-cors, *cors, same-origin
            headers: { Authorization: 'Bearer ' + accessToken },
            body: JSON.stringify(payload)
          };

        let resultStatus = null;
        fetch(apiUrl, params).then(response => { resultStatus = response.ok; response.json()
          .then((data) => {
            callback(data, resultStatus);
            });
        }).catch( (error) => {
              console.error(error);

          })
        }
    )
}