import React from 'react';
import { helperLib } from '@buyerson/pricingsaas-pricebook'
import { callPutApi } from '../helpers/apiHelper';
import { callGetApi } from "../helpers/apiHelper";
import { frequencies } from '@buyerson/pricingsaas-pricebook/dist/charge';

export class EditPricebook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: "",
      pricebook: {},
      freemium: false,
      planName: "",
      price: "",
      planType: "",
      plans: [],
      valueMetricName: "",
      freeTrial: false
    }
  }

  componentDidMount() {

    const queryParams = new URLSearchParams(window.location.search)
    const slug = queryParams.get("slug");
    this.setState({ slug })
    const callback = (data) => {
      const planName = [];
      for (let i = 0; i < data.data.plans.length; i++) {
        planName.push(data.data.plans[i].name);
      }

      // console.log(data.data.plans.map( (plan) => { return {  name: plan.name, id: plan.id, trial : plan.freeTrial} }));
      console.log(data.data);

      this.setState({
        pricebook: data.data,
        freemium: this.checkFree(data.data),
        plans: data.data.plans.map(plan => ({ name: plan.name, frequency: helperLib.getBaseCharge(plan).frequency, id: plan.id, price: helperLib.getBaseCharge(plan).price, freeTrial: plan.freeTrial })),
        valueMetricName: data.data.valueMetrics.length === 0 ? "" : data.data.valueMetrics[0].name,
        freeTrial: data.data.plans.map(plan => ({freeTrial: plan.freeTrial}))
      });
    }

    callGetApi(process.env.REACT_APP_AUDIENCE + "/scraper/getPricebook?slug=" + slug, callback);
  }

  submitForm() {

    try {

      const pricebook = this.state.pricebook;
      const plans = this.state.plans;

      if (this.state.freemium && !helperLib.doesPricebookHaveFreePlan(pricebook)) {
        this.addFreemium(pricebook);
      }
      else if (!this.state.freemium && helperLib.doesPricebookHaveFreePlan(pricebook)) {
        this.removeFreemium(pricebook);
      }

      if (this.state.valueMetricName) {
        this.addPrimaryValueMetric(pricebook, this.state.valueMetricName);
      } else {
        helperLib.removePrimaryValueMetric(pricebook);
      }

      for (let i = 0; i < plans.length; i++) {

          const isNewPlan = Boolean(!plans[i].id);
          if (!isNewPlan) {
            const pricebookPlan = pricebook.plans.find(p => p.id === plans[i].id);
            let price = plans[i].price;
            if (plans[i].frequency === "monthly") {
              helperLib.changeFrequency(pricebookPlan, frequencies.MONTHLY);
            }
            else if (plans[i].frequency === "annual") {
              helperLib.changeFrequency(pricebookPlan, frequencies.ANNUAL);
            }
            else if (plans[i].frequency === "custom") {
              price = -1;
              helperLib.changeFrequency(pricebookPlan, frequencies.CUSTOM);
            }

            if (plans[i].freeTrial && !helperLib.doesPlanHaveFreeTrial(pricebookPlan)) {
              helperLib.addFreeTrial(pricebook, pricebookPlan);
            }
            else if (!plans[i].freeTrial && helperLib.doesPlanHaveFreeTrial(pricebookPlan)) {
                helperLib.removeFreeTrial(pricebook, pricebookPlan);
            }

            helperLib.changePrice(pricebookPlan, price);
            let name = plans[i].name;
            helperLib.changeName(pricebookPlan, name);
        }
        else {
          let pricebookPlan = null;
          if (plans[i].frequency === "monthly") {
            pricebookPlan = helperLib.addMonthlyPlan(pricebook, plans[i].name, plans[i].price);
          }
          else if (plans[i].frequency === "annual") {
            pricebookPlan = helperLib.addAnnualPlan(pricebook, plans[i].name, plans[i].price);
          }
          else if (plans[i].frequency === "custom") {
            pricebookPlan = helperLib.addEnterprisePlan(pricebook);
          }
          if (pricebookPlan) {
            if(!plans[i].freeTrial) {
              helperLib.addFreeTrial(pricebook, pricebookPlan);
            }
          }
        }
        for (let i = 0; i < pricebook.plans.length; i++) {
          const pricebookPlan = pricebook.plans[i];
          const doesPlanExist = plans.find(p => p.id === pricebookPlan.id);
          if (!doesPlanExist) {
            helperLib.removePlan(pricebook, pricebookPlan);
          }
        }

      }

      const callback = (data) => {
        this.setState({
          message: data.message
        });
        window.location.href = "/companies";
      }


      const pram = { pricebook, slug: this.state.slug };
      callPutApi(process.env.REACT_APP_AUDIENCE + "/scraper/updatePricebook", pram, callback);

    } catch (e) {
      console.error('Caught an exception!', e.toString());
    }
  }

  addFreemium(pricebook) {
    const add = helperLib.addFreemium(pricebook);
    return add;
  }

  removeFreemium(pricebook) {
    const remove = helperLib.removeFreemiumPlan(pricebook);
    return remove;
  }

  checkFree(pricebook) {
    try {
      const check = helperLib.doesPricebookHaveFreePlan(pricebook);
      return check;
    }
    catch {
      return false;
    }

  }

  updatePlanName(name, index) {
    const plans = this.state.plans
    plans[index] = name;
    this.setState({ plans })
  }

  removePlan(index) {
    const plans = [...this.state.plans];
    plans.splice(index, 1);
    this.setState({ plans })
  }

  addPlan() {
    const plans = this.state.plans;
    plans.push({
      id: null,
      name: "new plan",
      frequency: "annual",
      price: 1
    });
    this.setState({ plans })
  }

  addPrimaryValueMetric(pricebook, valueMetricName) {
    if (valueMetricName === "") {
    }
    helperLib.addPrimaryValueMetric(pricebook, valueMetricName);
    return pricebook;
  }



  getEditContent() {
    return (
      <>
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">Pricebook Profile ({this.state.slug})</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Edit information about pricebook
              </p>
            </div>
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Freemium Plan
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="flex max-w-lg rounded-md shadow-sm">
                    <label>
                      <input type='checkbox' checked={this.state.freemium} onChange={(event) => { this.setState({ freemium: !this.state.freemium }) }} />
                      &nbsp; Includes Freemium
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 ">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Plan List
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0 ">
                <ul>
                  {this.state.plans.map((plan, index) =>
                    <div className="flex flex-row justify-between">
                      <div>
                        <input type="text" value={plan.name} onChange={(event) => this.setState(state => {
                          const plans = state.plans.map((item, j) => {
                            if (j === index) {
                              return { ...item, name: event.target.value };
                            } else {
                              return item;
                            }
                          });
                          return {
                            plans,
                          }
                        })} />
                      </div>
                      <div>
                        <label>
                          <input
                          type="checkbox"
                          checked={plan.freeTrial}
                          onChange={() => this.setState(state => {
                            const plans = state.plans.map((item, j) => {
                              if (j === index) {
                                return {...item, freeTrial: !plan.freeTrial}
                              }
                              else {
                                return item;
                              }
                            });
                            return {
                              plans,
                            }
                          })}
                          />
                          Free trial
                        </label>
                      </div>
                      <div>
                        <select
                          id="type"
                          name="type"
                          value={plan.frequency}
                          autoComplete="type-name"
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                          onChange={(event) => this.setState(state => {
                            const plans = state.plans.map((item, j) => {
                              if (j === index) {
                                if (event.target.value === "custom") {
                                  return { ...item, frequency: event.target.value, price: -1 }
                                }
                                else {
                                  return { ...item, frequency: event.target.value }
                                }
                              } else {
                                return item;
                              }
                            });
                            return {
                              plans,
                            }
                          })}
                        >
                          {plan.frequency === undefined ? <option value="annual"> annual </option> : <option> {plan.frequency} </option>}
                          {plan.frequency === "monthly" ? <option value="annual"> annual </option> : <option value="monthly"> monthly </option>}
                          {plan.frequency === "custom" ? <option value="monthly"> annual </option> : <option value="custom"> custom </option>}

                        </select>
                      </div>
                      <div>
                        <button onClick={() => this.removePlan(index)}>
                          Remove
                        </button>
                      </div>
                    </div>
                  )}
                </ul>


                <div className='float-right mt-3'>
                  <button
                    onClick={() => this.addPlan()}>
                    Add Plan
                  </button>
                </div>
              </div>
            </div>
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Primary Value Metrics
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0 flex flex-row justify-between">
                  <div>
                    <input
                      type="text"
                      value={this.state.valueMetricName}
                      id="valueMetric"
                      onChange={(event) => this.setState({ valueMetricName: event.target.value })}
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <hr />
        <div className="pt-5">
          <div className="flex justify-end">
            <button
              onClick={() => this.submitForm()}
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </div>
      </>
    )
  }

  render() {
    let content = "";
    if (!this.state.plans) return <>loading...</>
    content = this.getEditContent();
    return (<>
      <div className="flex flex-col pt-9">
        <div>{content}</div>
      </div></>)

  }
}

export default EditPricebook;